<template>
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid px-0">
        <div class="row mb-2">
          <div class="col-sm-6 py-0">
            <h1>Отслеживание заказа</h1>
          </div>
          <div class="col-sm-6 py-0">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Главная</a></li>
              <li class="breadcrumb-item active">История заказа</li>
            </ol>
          </div>
        </div> </div
      ><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content pt-2">
      <div class="container-fluid px-0">
        <!-- Timelime example  -->
        <div class="row">
          <div class="col-md-12 py-0">
            <!-- The time line -->
            <div class="timeline">
              <!-- timeline time label -->
              <div class="time-label">
                <span class="bg-red">10 Feb. 2014</span>
              </div>
              <!-- /.timeline-label -->
              <!-- timeline item -->
              <div>
                <i class="fas fa-envelope bg-blue"></i>
                <div class="timeline-item">
                  <span class="time"><i class="fas fa-clock"></i> 12:05</span>
                  <h3 class="timeline-header"
                    ><a href="#">Support Team</a> sent you an email</h3
                  >

                  <div class="timeline-body">
                    Etsy doostang zoodles disqus groupon greplin oooj voxy
                    zoodles, weebly ning heekya handango imeem plugg dopplr
                    jibjab, movity jajah plickers sifteo edmodo ifttt zimbra.
                    Babblely odeo kaboodle quora plaxo ideeli hulu weebly
                    balihoo...
                  </div>
                  <div class="timeline-footer">
                    <a class="btn btn-primary btn-sm">Read more</a>
                    <a class="btn btn-danger btn-sm">Delete</a>
                  </div>
                </div>
              </div>
              <!-- END timeline item -->
              <!-- timeline item -->
              <div>
                <i class="fas fa-user bg-green"></i>
                <div class="timeline-item">
                  <span class="time"
                    ><i class="fas fa-clock"></i> 5 mins ago</span
                  >
                  <h3 class="timeline-header no-border"
                    ><a href="#">Sarah Young</a> accepted your friend
                    request</h3
                  >
                </div>
              </div>
              <!-- END timeline item -->
              <!-- timeline item -->
              <div>
                <i class="fas fa-comments bg-yellow"></i>
                <div class="timeline-item">
                  <span class="time"
                    ><i class="fas fa-clock"></i> 27 mins ago</span
                  >
                  <h3 class="timeline-header"
                    ><a href="#">Jay White</a> commented on your post</h3
                  >
                  <div class="timeline-body">
                    Take me to your leader! Switzerland is small and neutral! We
                    are more like Germany, ambitious and misunderstood!
                  </div>
                  <div class="timeline-footer">
                    <a class="btn btn-warning btn-sm">View comment</a>
                  </div>
                </div>
              </div>
              <!-- END timeline item -->
              <!-- timeline time label -->
              <div class="time-label">
                <span class="bg-green">3 Jan. 2014</span>
              </div>
              <!-- /.timeline-label -->
              <!-- timeline item -->
              <div>
                <i class="fa fa-camera bg-purple"></i>
                <div class="timeline-item">
                  <span class="time"
                    ><i class="fas fa-clock"></i> 2 days ago</span
                  >
                  <h3 class="timeline-header"
                    ><a href="#">Mina Lee</a> uploaded new photos</h3
                  >
                  <div class="timeline-body">
                    <img src="https://placehold.it/150x100" alt="..." />
                    <img src="https://placehold.it/150x100" alt="..." />
                    <img src="https://placehold.it/150x100" alt="..." />
                    <img src="https://placehold.it/150x100" alt="..." />
                    <img src="https://placehold.it/150x100" alt="..." />
                  </div>
                </div>
              </div>
              <!-- END timeline item -->
              <!-- timeline item -->
              <div>
                <i class="fas fa-video bg-maroon"></i>

                <div class="timeline-item">
                  <span class="time"
                    ><i class="fas fa-clock"></i> 5 days ago</span
                  >

                  <h3 class="timeline-header"
                    ><a href="#">Mr. Doe</a> shared a video</h3
                  >

                  <div class="timeline-body">
                    <div class="embed-responsive embed-responsive-16by9">
                      <iframe
                        class="embed-responsive-item"
                        src="https://www.youtube.com/embed/tMWkeBIohBs"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                  <div class="timeline-footer">
                    <a href="#" class="btn btn-sm bg-maroon">See comments</a>
                  </div>
                </div>
              </div>
              <!-- END timeline item -->
              <div>
                <i class="fas fa-clock bg-gray"></i>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
      </div>
      <!-- /.timeline -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: 'Timeline',
}
</script>

<style lang="scss" scoped>
.timeline {
  margin: 0 0 45px;
  padding: 0;
  position: relative;
}

.timeline::before {
  border-radius: 0.25rem;
  background-color: #dee2e6;
  bottom: 0;
  content: '';
  left: 31px;
  margin: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.timeline > div {
  margin-bottom: 15px;
  margin-right: 10px;
  position: relative;
}

.timeline > div::before,
.timeline > div::after {
  content: '';
  display: table;
}

.timeline > div > .timeline-item {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  background-color: #fff;
  color: #495057;
  margin-left: 60px;
  margin-right: 15px;
  margin-top: 0;
  padding: 0;
  position: relative;
}

.timeline > div > .timeline-item > .time {
  color: #999;
  float: right;
  font-size: 12px;
  padding: 10px;
}

.timeline > div > .timeline-item > .timeline-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  color: #495057;
  font-size: 16px;
  line-height: 1.1;
  margin: 0;
  padding: 10px;
}

.timeline > div > .timeline-item > .timeline-header > a {
  font-weight: 600;
}

.timeline > div > .timeline-item > .timeline-body,
.timeline > div > .timeline-item > .timeline-footer {
  padding: 10px;
}
.timeline-body {
  font-size: 16px;
}

.timeline > div > .timeline-item > .timeline-body > img {
  margin: 10px;
}

.timeline > div > .timeline-item > .timeline-body > dl,
.timeline > div > .timeline-item > .timeline-body ol,
.timeline > div > .timeline-item > .timeline-body ul {
  margin: 0;
}

.timeline > div > .timeline-item > .timeline-footer > a {
  color: #fff;
  font-size: 14px;
}

.timeline > div > .fa,
.timeline > div > .fas,
.timeline > div > .far,
.timeline > div > .fab,
.timeline > div > .fal,
.timeline > div > .fad,
.timeline > div > .svg-inline--fa,
.timeline > div > .ion {
  background-color: #adb5bd;
  border-radius: 50%;
  font-size: 16px;
  height: 30px;
  left: 18px;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 30px;
}

.timeline > div > .svg-inline--fa {
  padding: 7px;
}

.timeline > .time-label > span {
  border-radius: 4px;
  background-color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 5px;
}

.timeline-inverse > div > .timeline-item {
  box-shadow: none;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}

.timeline-inverse > div > .timeline-item > .timeline-header {
  border-bottom-color: #dee2e6;
}

.dark-mode .timeline::before {
  background-color: #6c757d;
}

.dark-mode .timeline > div > .timeline-item {
  background-color: #343a40;
  color: #fff;
  border-color: #6c757d;
}

.dark-mode .timeline > div > .timeline-item > .timeline-header {
  color: #ced4da;
  border-color: #6c757d;
}

.dark-mode .timeline > div > .timeline-item > .time {
  color: #ced4da;
}
</style>